import { enableProdMode, importProvidersFrom } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';
import { provideRouter, withRouterConfig } from '@angular/router';
import { routes } from './app/app.routes';
import { ToastrModule } from 'ngx-toastr';
import { ApiModule } from './app/api/api.module';
import { provideAnimations } from '@angular/platform-browser/animations';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { AuthInterceptor } from './app/auth/interceptors/auth-interceptor/auth.interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FeatherModule } from 'angular-feather';
import { allIcons } from 'angular-feather/icons';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { FONT_PICKER_CONFIG, FontPickerConfigInterface, FontPickerService } from 'ngx-font-picker';
import { MonacoEditorModule } from 'ngx-monaco-editor-v2';

const DEFAULT_FONT_PICKER_CONFIG: FontPickerConfigInterface = {
  apiKey: 'AIzaSyAEEF_vfDCSUkgUPALJjVXmSlBsjeWpq2c'
};

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(routes,
      withRouterConfig({
        defaultQueryParamsHandling: 'merge' // preserve query params
      })
    ),
    provideAnimations(),
    importProvidersFrom(
      ToastrModule.forRoot({
        timeOut: 5000,
        positionClass: 'toast-bottom-right',
        easeTime: 125,
      }),
      FormsModule,
      ReactiveFormsModule,
      ApiModule.forRoot({ rootUrl: environment.api }),
      FeatherModule.pick(allIcons),
      NgxSmartModalModule.forRoot(),
      MonacoEditorModule.forRoot()
    ),
    FontPickerService,
    {
      provide: FONT_PICKER_CONFIG,
      useValue: DEFAULT_FONT_PICKER_CONFIG
    },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport())
  ]
}).catch(err => console.error(err));
