import { Component, OnInit, viewChild, Signal } from '@angular/core';
import { NgProgressbar, NgProgressRef } from 'ngx-progressbar';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [RouterModule, NgProgressbar]
})
export class AppComponent implements OnInit {
  progressBar: Signal<NgProgressRef> = viewChild(NgProgressRef);
  constructor(
    private router: Router
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.progressBar().start()
      }
      if (event instanceof NavigationEnd || event instanceof NavigationCancel) {
        this.progressBar().complete();
        window.scrollTo(0, 0);
      }
    })

    // block dashboard analytics (plausible) on dev/staging
    if (environment.name !== 'production') window.localStorage.setItem("plausible_ignore", "true");

  }
}
