import { Routes } from '@angular/router';
import { AuthGuard } from './auth/guards/auth/auth.guard';
import { UserResolver } from './main-layout/resolvers/user/user.resolver';
import { ProcessingResolver } from './main-layout/resolvers/processing/processing.resolver';

export const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main-layout/main-layout.module').then(m => m.MainLayoutModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    loadComponent: () => import('./login/login.component').then(m => m.LoginComponent),
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
  },
  {
    path: 'forgot-password',
    loadComponent: () => import('./unauth-pages/forgot-password/forgot-password.component').then(c => c.ForgotPasswordComponent),
  },
  {
    path: 'reset-password/:token',
    loadComponent: () => import('./unauth-pages/reset-password/reset-password.component').then(c => c.ResetPasswordComponent),
  },
  {
    path: 'verify-email/:token',
    loadComponent: () => import('./unauth-pages/verify-email/verify-email.component').then(c => c.VerifyEmailComponent),
  },
  {
    path: 'error/:errorCode',
    loadComponent: () => import('./pages/error-page/error-page.component').then(c => c.ErrorPageComponent),
  },
  {
    path: 'plans',
    loadComponent: () => import('./signup/pages/plans-page/plans-page.component').then(c => c.PlansPageComponent),
    resolve: {
      user: UserResolver
    }
  },
  {
    path: 'processing',
    loadComponent: () => import('./signup/pages/processing-page/processing-page.component').then(c => c.ProcessingPageComponent),
    resolve: {
      user: ProcessingResolver
    }
  },
  {
    path: 'templates/:template',
    loadComponent: () => import('./signup/pages/template-preview/template-preview.component').then(c => c.TemplatePreviewComponent),
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: 'error/404',
  }
]; 